const getBuildEnvironment = () => {
  return {
    mapboxAccessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
    auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    auth0ClientId: import.meta.env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: import.meta.env?.VITE_AUTH0_DOMAIN,
    isIntegrationTest: import.meta.env.MODE === 'integration',
    apiBaseUrl: import.meta.env?.VITE_API_BASE_URL,
    sentryDsn: import.meta.env.VITE_SENTRY_DSN,
    sentryAuthToken: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    mode: import.meta.env.MODE,
    dev: import.meta.env.DEV,
    appVersion: import.meta.env.VITE_APP_VERSION,
  };
};

const getRuntimeEnvironment = () => {
  const env = window.__env__ || {};

  const envVars = {
    mapboxAccessToken: env.VITE_MAPBOX_ACCESS_TOKEN,
    auth0Audience: env.VITE_AUTH0_AUDIENCE,
    auth0ClientId: env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: env?.VITE_AUTH0_DOMAIN,
    isIntegrationTest: env.MODE ? env.MODE === 'integration' : undefined,
    apiBaseUrl: env?.VITE_API_BASE_URL,
    sentryDsn: env.VITE_SENTRY_DSN,
    sentryAuthToken: env.VITE_SENTRY_AUTH_TOKEN,
    mode: env.MODE,
    dev: env.DEV,
    appVersion: env.VITE_APP_VERSION,
  };

  // Filter out entries with undefined values
  const definedVars = Object.fromEntries(
    Object.entries(envVars).filter(([, value]) => value !== undefined),
  );

  return definedVars;
};

export const getEnvironment = () => {
  const env = {
    ...getBuildEnvironment(),
    ...getRuntimeEnvironment(),
  };

  return env;
};
